import React, { useCallback, ChangeEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { debounce } from "lodash";
import moment from "moment";
import { Chip } from "@mui/material";
import * as XLSX from "xlsx";

import report from "../../assets/report.svg";
import filter from "../../assets/filter.svg";

import Search from "../SearchBar/Search";
import Filter from "../Filter/Filter";
import TablePaginationDemo from "../common/Pagenation";
import { Fetch_Project_Report_List } from "../common/services/ProjectReport";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface ProjectData {
  id: number;
  project_name: string;
  taskCount: string;
  resourceCount: string;
  duration: number;
}

interface Column {
  id: string;
  displayName: string;
}

interface ProjectReportData {
  [key: string]: any;
}

function ProjectReport() {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState<ProjectData[]>([]);
  const [numOfItems, setNumOfItems] = useState(10);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const bussness: Business_Unit[] = [];
  const department: Department_Unit[] = [];
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [dateRange, setDateRange] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);
  const [reportData, setReportData] = useState<ProjectReportData[]>([]);
  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [startingDate, endingDate] = dates;
    if (startingDate && endingDate) setIsCalendarOpen(!isCalendarOpen);
    setSelectedDates(dates);
  };

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleFilterApply = () => {
    setStartDate(selectedDates[0]);
    setEndDate(selectedDates[1]);

    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    }
    setIsOpen(false);
  };

  const handleDeleteDate = () => {
    setDateRange(false);
    setSelectedDates([null, null]);
    setStartDate(null);
    setEndDate(null);
    // fetchDSR();
  };

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {};

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {};

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    }, 100),
    []
  );

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const handleDownload = () => {
    if (dataFetched) {
      // Generate worksheet data from reportData
      const worksheetData = [
        columns.map((col) => col.displayName), // Header row
        ...reportData.map((row) =>
          columns.map((col) => row[col.id]?.toString() || "")
        ), // Data rows
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Apply formatting to the header row (make bold)
      const headerRange = XLSX.utils.decode_range(worksheet["!ref"] || "");
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
        if (!worksheet[cellAddress]) continue;
        worksheet[cellAddress].s = {
          font: { bold: true }, // Make headers bold
          alignment: { horizontal: "center", vertical: "center" }, // Center headers
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
          fill: {
            fgColor: { rgb: "E0EBF5" }, // Light background color
          },
        };
      }

      // Auto-width columns based on their content
      worksheet["!cols"] = columns.map(() => ({ wch: 20 })); // Default column width

      // Add cell borders to all data cells
      for (let R = headerRange.s.r + 1; R <= headerRange.e.r; ++R) {
        for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
          if (!worksheet[cellAddress]) continue;
          worksheet[cellAddress].s = {
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
            alignment: { horizontal: "left", vertical: "center" }, // Align text properly
          };
        }
      }

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Project Report");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "Project_Report.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.warn("Data has not been fetched yet.");
    }
  };

  const fetchData = async () => {
    try {
      const response = await Fetch_Project_Report_List(
        customer_id,
        numOfItems,
        page,
        searchTerm,
        startDate,
        endDate
      );
      if (response.status === 200) {
        setTableData(response.data.result.rows);
        setCount(response.data.result.count);

        const fetchedData: ProjectReportData[] = response.data.result.rows;
        const columnMapping: { [key: string]: string } = {
          project_name: "Project Name",
          taskCount: "Total Tasks",
          resourceCount: "Total Resources",
          duration: "Time Spent (in Hrs.)",
        };

        const filteredData = fetchedData?.map((row) => {
          const filteredRow: Partial<ProjectReportData> = {};

          Object.keys(columnMapping).forEach((key) => {
            const keys = key.split(".");

            if (keys.length === 1) {
              if (row.hasOwnProperty(keys[0])) {
                filteredRow[keys[0]] = row[keys[0]];
              }
            }
          });

          return filteredRow;
        });

        setReportData(filteredData);

        const cols: Column[] = Object.keys(columnMapping).map((key) => ({
          id: key,
          displayName: columnMapping[key],
        }));
        setColumns(cols);

        setDataFetched(true);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const isFirstRender = useRef(true);

  useEffect(() => {
    // if (isFirstRender.current) {
    //   isFirstRender.current = false;
    //   return;
    // }
    fetchData();
  }, [searchTerm, page, numOfItems, startDate, endDate]);

  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-9">
          <div className="flex justify-between">
            <span className="font-[Poppins] font-semibold text-xl text-[#1D1A22]">
              Project Report
            </span>
            <button
              className="px-[19px] py-[11px] rounded-lg buttoncss"
              onClick={handleDownload}
            >
              <div className="flex justify-between items-center gap-[7px]">
                <img src={report} alt="report" />
                <span className="font-[Poppins] font-semibold text-base text-white">
                  Generate Report
                </span>
              </div>
            </button>
          </div>
          <div className="h-[40px]">
            <div className="h-full flex justify-between items-center">
              <Search
                onChange={updateSearchTerm}
                label="Search with Project Name"
              />
              <div className="h-full flex items-center gap-5">
                <div className="h-full flex items-center gap-2">
                  {dateRange && (
                    <Chip
                      label={`${moment(selectedDates[0]).format(
                        "DD MMM YYYY"
                      )} to ${moment(selectedDates[1]).format("DD MMM YYYY")}`}
                      onDelete={handleDeleteDate}
                      size="small"
                    />
                  )}
                </div>
                <button
                  className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white hover:bg-gray-100"
                  onClick={() => setIsOpen(true)}
                >
                  <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                    Filter
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="grid grid-cols-3 gap-2.5 custom:grid-cols-2">
            {tableData?.map((item) => (
              <div className="bg-white rounded-[10px] border-l-4 border-[#AD8CF1] shadow-custom p-4">
                <div className="flex flex-col gap-2">
                  <div className="flex items-start h-10 p-0 border-b border-[#EDEDED]">
                    <div className="flex justify-between items-center h-6 w-full">
                      <span className="font-[Poppins] font-medium text-[17px] leading-[16.24px] tracking-[-0.34px] text-black">
                        {item?.project_name || "--"}
                      </span>
                      <button
                        className="rounded-md px-[7px]"
                        style={{ border: "2px solid var(--22, #4165C4)" }}
                        onClick={() =>
                          navigate("/view-project", {
                            state: { id: item.id },
                          })
                        }
                      >
                        <span className="font-[Poppins] text text-[10px] leading-[29px]">
                          View Details
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="grid grid-cols-3">
                    <div className="flex flex-col gap-[5px]">
                      <span className="font-[Poppins] font-normal text-[10px] leading-[16.24px] text-[#49454F]">
                        Consumed hrs.
                      </span>
                      <span className="font-[Poppins] font-semibold text-[16px] leading-[11.19px] text-[#322F37]">
                        {item?.duration || "--"}
                      </span>
                    </div>
                    <div className="flex flex-col gap-[5px]">
                      <span className="font-[Poppins] font-normal text-[10px] leading-[16.24px] text-[#49454F]">
                        Total Tasks:
                      </span>
                      <span className="font-[Poppins] font-semibold text-[16px] leading-[11.19px] text-[#322F37]">
                        {item?.taskCount}
                      </span>
                    </div>
                    <div className="flex flex-col gap-[5px]">
                      <span className="font-[Poppins] font-normal text-[10px] leading-[16.24px] text-[#49454F]">
                        Resources:
                      </span>
                      <span className="font-[Poppins] font-semibold text-[16px] leading-[11.19px] text-[#322F37]">
                        {item?.resourceCount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {count >= 10 && (
            <TablePaginationDemo
              count={count}
              handleItemsChange={handleNumberOfPages}
              handlePageChange={handlePageChange}
              currentPage={1}
              numOfItems={10}
            />
          )}
        </div>
      </div>
      <Filter
        isDate
        isOther={false}
        heading="Project Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </div>
  );
}

export default ProjectReport;
