import React, { useCallback, useEffect, useState, useRef } from "react";
import edit from "../../assets/Crud_Icons/edit.svg";
import profile from "../../assets/Ellipse 580.png";
import TablePaginationDemo from "../common/Pagenation";
import { useNavigate } from "react-router-dom";
import download from "../../image/Vector (1).png";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Fetch_Attendence_List } from "../common/services/Attendence";
import { AttendanceActionData } from "../../redux/reducers/AttendenceSlice/AttendenceSlice";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import maskPlus from "../../image/Mask group.svg";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import clock from "../../assets/clock.svg";
import location from "../../assets/location.svg";
import filter from "../../assets/filter.svg";
import Search from "../SearchBar/Search";
import Filter from "../Filter/Filter";
import { Chip } from "@material-ui/core";
import * as XLSX from "xlsx";

dayjs.extend(duration);

interface RowData {
  User: {
    firstName: string;
    lastName: string;
    employee_id: string;
    profile_image: string;
  };
  start_time: string;
  check_in_address: string;
  end_time: string;
  check_out_address: string;
  id: number;
  mode_of_work: string;
}
interface Items {
  start_time: string;
  end_time: string;
}

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface DepartmentUnit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface Column {
  id: string;
  displayName: string;
}

interface EmployeeData {
  [key: string]: any;
}

const LocationAndAddress: React.FC<{
  dateTime: string;
  address: string;
}> = ({ dateTime, address }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <div className="flex flex-col justify-center gap-1">
          <div className="flex gap-1 justify-start items-center">
            <img
              src={clock}
              alt="time"
              className="h-[12px] w-[12px] text-[#49454F]"
            />
            <span className="text-[#1D1A22] text-[14px] font-[Poppins] font-[500]">
              {dateTime}
            </span>
          </div>
          {address ? (
            <div className="flex gap-1 items-center">
              <img
                src={location}
                alt=""
                className="h-[12px] w-[12px] text-[#938F99]"
              />
              <span className="text-[#938F99] text-[14px] font-[Poppins] font-[500] overflow-hidden whitespace-nowrap text-ellipsis w-40">
                {address}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>
          <div className="flex flex-col justify-center gap-1">
            <div className="flex gap-1 justify-start items-center">
              <img
                src={clock}
                alt="time"
                className="h-[12px] w-[12px] text-[#49454F]"
              />
              <span className="text-[#1D1A22] text-[14px] font-[Poppins] font-[500]">
                {dateTime}
              </span>
            </div>
            <div className="flex gap-1 items-center">
              <img
                src={location}
                alt=""
                className="h-[12px] w-[12px] text-[#938F99]"
              />
              <span className="text-[#938F99] text-[14px] font-[Poppins] font-[500]">
                {address}
              </span>
            </div>
          </div>
        </Typography>
      </Popover>
    </div>
  );
};

const AttendenceDetails = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const startDate = useSelector((state: any) => state.attendence.startDate);

  const endDate = useSelector((state: any) => state.attendence.endDate);

  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [tempSelectedDates, setTempSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [dateRange, setDateRange] = useState(false);
  const [departmentSelect, setDepartmentSelect] = useState(false);
  const [tableData1, setTableData1] = useState<EmployeeData[]>([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);
  const [businessSelect, setBusinessSelect] = useState(false);
  const [department, setDepartment] = useState<DepartmentUnit[]>();
  const [selectedDepartmentUnit, setSelectedDepartmentUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [tempSelectedBusinessUnit, setTempSelectedBusinessUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [tempSelectedDepartmentUnit, setTempSelectedDepartmentUnit] = useState<
    Business_Unit | DepartmentUnit
  >();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const response = await Fetch_Attendence_List(
        customer_id,
        numOfItems,
        page,
        Number(selectedBusinessUnit?.id),
        Number(selectedDepartmentUnit?.id),
        searchTerm,
        startDate,
        endDate
      );
      if (response.status === 200) {
        const fetchedData: EmployeeData[] = response.data.result.rows;
        const columnMapping: { [key: string]: string } = {
          "User.firstName": "First Name",
          "User.lastName": "Last Name",
          date: "Date",
          status: "Status",
          start_time: "CheckIn Time",
          check_in_address: "Check In Address",
          mode_of_work: "CheckIn Mode",
          end_time: "Check Out Time",
          check_out_address: "Check Out Address",
          mode_of_work_checkout: "CheckOut Mode",
          checkout_by: "Check Out By",
        };

        const filteredData = fetchedData?.map((row) => {
          const filteredRow: Partial<EmployeeData> = {};

          Object.keys(columnMapping).forEach((key) => {
            const keys = key.split(".");

            if (keys.length === 1) {
              if (row.hasOwnProperty(keys[0])) {
                if (keys[0] === "start_time" && row[keys[0]]) {
                  filteredRow[keys[0]] = dayjs(row[keys[0]]).format(
                    "MMM DD, YYYY HH:mm"
                  );
                } else if (keys[0] === "end_time" && row[keys[0]]) {
                  filteredRow[keys[0]] = dayjs(row[keys[0]]).format(
                    "MMM DD, YYYY HH:mm"
                  );
                } else {
                  filteredRow[keys[0]] = row[keys[0]];
                }
                // filteredRow[keys[0]] = row[keys[0]];
              }
            } else if (keys.length === 2) {
              const parentKey = keys[0];
              const childKey = keys[1];
              if (row[parentKey] && row[parentKey].hasOwnProperty(childKey)) {
                // Store the nested value under the child key
                filteredRow[key] = row[parentKey][childKey];
              } else {
                filteredRow[key] = null; // Set to null if the nested value doesn't exist
              }
            }
          });

          const startTime = row.start_time ? dayjs(row.start_time) : null;
          const endTime = row.end_time ? dayjs(row.end_time) : null;

          if (startTime && endTime) {
            const durationMinutes = endTime.diff(startTime, "minute");
            const hours = Math.floor(durationMinutes / 60);
            const minutes = Math.floor((durationMinutes % 60) / 0.6);

            // Format duration as HH:mm
            const formattedDuration = `${String(hours).padStart(
              2,
              ""
            )}.${String(minutes)}`;
            filteredRow["duration"] = formattedDuration;
          } else {
            filteredRow["duration"] = "N/A"; // Handle cases where either start or end time is missing
          }

          return filteredRow;
        });

        setTableData1(filteredData);

        const cols: Column[] = Object.keys(columnMapping).map((key) => ({
          id: key,
          displayName: columnMapping[key],
        }));

        cols.push({ id: "duration", displayName: "Duration" });

        setColumns(cols);

        setDataFetched(true);

        const dataWithDurations = response.data.result?.rows?.map(
          (item: Items) => {
            const startDateTime = dayjs(item.start_time);
            const endDateTime = dayjs(item.end_time);
            const duration = dayjs.duration(endDateTime.diff(startDateTime));

            // Calculate the total duration in hours and minutes
            const totalMinutes = Math.floor(duration.asMinutes());
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            // Format hours and minutes to ensure two digits
            const formattedHours = String(hours).padStart(2, "0");
            const formattedMinutes = String(minutes).padStart(2, "0");

            const durationString = `${formattedHours}:${formattedMinutes}`;

            return {
              ...item,
              duration: durationString,
            };
          }
        );

        setTableData(dataWithDurations);

        setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; // Skip fetchData on initial render
    }

    fetchData();
  }, [
    page,
    numOfItems,
    searchTerm,
    startDate,
    endDate,
    selectedBusinessUnit?.id,
    selectedDepartmentUnit?.id,
    startSession,
    endSession,
  ]);

  const handleDownload = () => {
    if (dataFetched) {
      // Generate worksheet data from tableData1
      const worksheetData = [
        columns.map((col) => col.displayName), // Header row
        ...tableData1.map((row) =>
          columns.map((col) => row[col.id]?.toString() || "")
        ), // Data rows
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "attendence.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.warn("Data has not been fetched yet.");
    }
  };

  const handleBusinessUnitSelect = (option: Business_Unit | DepartmentUnit) => {
    setTempSelectedBusinessUnit(option);
  };

  const handleDepartmentUnitSelect = (
    option: Business_Unit | DepartmentUnit
  ) => {
    setTempSelectedDepartmentUnit(option);
  };

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {
      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [startingDate, endingDate] = dates;
    if (startingDate && endingDate) setIsCalendarOpen(!isCalendarOpen);
    setSelectedDates(dates);
  };

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000),
    [setSearchTerm]
  );

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, [customer_id, dispatch, navigate]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchDepartment();
  }, [customer_id, dispatch, navigate]);

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleFilterApply = () => {
    const [startDate, endDate] = selectedDates;

    setTempSelectedDates(selectedDates);

    setSelectedBusinessUnit(tempSelectedBusinessUnit);
    setSelectedDepartmentUnit(tempSelectedDepartmentUnit);
    dispatch(AttendanceActionData.setStartDate(startDate));
    dispatch(AttendanceActionData.setEndDate(endDate));
    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    }
    tempSelectedDepartmentUnit && setDepartmentSelect(true);
    tempSelectedBusinessUnit && setBusinessSelect(true);
    setIsOpen(false);
    setSelectedDates([null, null]);
  };

  const handleDeleteDate = () => {
    setDateRange(false);
    dispatch(AttendanceActionData.setStartDate(null));
    dispatch(AttendanceActionData.setEndDate(null));
    setTempSelectedDates([null, null]);
    fetchData();
  };
  const handleDeleteDepartment = () => {
    setDepartmentSelect(false);
    setTempSelectedDepartmentUnit(undefined);
    setSelectedDepartmentUnit(undefined);
    fetchData();
  };
  const handleDeleteBusiness = () => {
    setBusinessSelect(false);
    setTempSelectedBusinessUnit(undefined);
    setSelectedBusinessUnit(undefined);
    fetchData();
  };

  return (
    <div className="px-4 pt-6 pb-12 min-h-[90vh] bg-[#F9F9F9]">
      <div className="flex justify-between">
        <div className="flex flex-row gap-[2px] justify-center items-center">
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Attendence Details
          </span>
        </div>
        <div>
          <button
            onClick={() => navigate("/attendance-management/add-attendence")}
            className="flex items-center font-['Poppins'] py-2 font-[600] text-[14px] px-4 buttoncss text-white rounded-lg"
          >
            <img className="w-5 h-5 mr-2" src={maskPlus} alt="plus icon" />
            Add Attendence Details
          </button>
        </div>
      </div>

      <div className="mt-6">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with employee name"
            />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex items-center gap-2">
                {dateRange && (
                  <Chip
                    label="Date Range"
                    onDelete={handleDeleteDate}
                    size="small"
                  />
                )}
                {departmentSelect && (
                  <Chip
                    label={selectedDepartmentUnit?.name}
                    onDelete={handleDeleteDepartment}
                    size="small"
                  />
                )}
                {businessSelect && (
                  <Chip
                    label={selectedBusinessUnit?.name}
                    onDelete={handleDeleteBusiness}
                    size="small"
                  />
                )}
              </div>
              <button
                className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
                onClick={() => setIsOpen(true)}
              >
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                  Filter
                </span>
              </button>
              <div className="h-full">
                <button
                  onClick={handleDownload}
                  className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text"
                >
                  <img
                    src={download}
                    alt="download"
                    className="h-[15px] w-[15px]"
                  />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                    Download Report
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-4">
        <table className="w-full min-w-max text-left table-auto border-[0.6px] bg-white border-[#E0E3E7]">
          <thead className="bg-[#E1E1E1]">
            <tr className="h-11">
              <th className="pl-3 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Employee Name & ID
              </th>
              <th className="pl-3 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Check In Time & Location
              </th>
              <th className="pl-3 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Check Out Time & Location
              </th>
              <th className="pl-3 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Duration
              </th>
              <th className="pl-3 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Workspace
              </th>
              <th className="pl-3 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((items: any) => (
              <>
                <tr className="h-10 border-[0.6px] border-solid border-[#E0E3E7] hover:bg-gray-100">
                  <td className="">
                    <div className="flex items-center gap-2 pl-3 pr-2.5">
                      <img
                        className="w-8 h-8 rounded-full"
                        src={items.User.profile_image || profile}
                        alt="Profile"
                      />
                      <div className="flex flex-col justify-between">
                        <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                          {items.User.firstName} {items.User.lastName}
                        </span>
                        <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
                          {items.User.employee_id || ""}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="hover:cursor-pointer">
                    <LocationAndAddress
                      dateTime={dayjs(items?.start_time).format(
                        "DD MMM YYYY, HH:mm"
                      )}
                      address={items?.check_in_address}
                    />
                  </td>
                  <td className="hover:cursor-pointer">
                    <div className="flex flex-col justify-center gap-1 pl-4">
                      <div className="flex gap-1 items-center">
                        {items.end_time ? (
                          <>
                            <LocationAndAddress
                              dateTime={dayjs(items?.end_time).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                              address={items?.check_out_address}
                            />
                          </>
                        ) : (
                          <span>--</span>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="pl-4">
                    {items.end_time ? items?.duration : "--"}
                  </td>
                  <td className="pl-4">{items?.mode_of_work}</td>
                  <td className="pl-3 pr-2.5 py-1">
                    <div className="flex items-center justify-center">
                      <img
                        className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                        src={edit}
                        onClick={() =>
                          navigate(
                            `/attendance-management/edit-attendence/${items.id}`,
                            {
                              state: { id: items.id },
                            }
                          )
                        }
                        alt="Edit"
                      />
                    </div>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={1}
          numOfItems={10}
        />
      )}
      <Filter
        isDate
        isOther
        heading="Attendance Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </div>
  );
};

export default AttendenceDetails;
