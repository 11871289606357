import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import toast from "react-hot-toast";
import moment from "moment"

import right from "../../image/chevron-right.png";
import check from "../../image/checkmark-circle-02.svg";
import profile from "../../assets/Ellipse 580.png";

import Receipt from "./ImageView";
import ModalViewer from "./ModalViewer";

import {
  approveRejectApi,
  fetchEditEmployeeExpense,
} from "../common/services/EmployeeExpense";

// !MODAL STYLE
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

// !INTERFACES
interface Approvers {
  approval_id: number;
  approval_status: string;
  role: string;
}

interface ExpenseData {
  expense_category_name: string;
  expense_date: string;
  expense_amount: string;
  firstName: string;
  lastName: string;
  profile_image: string;
  employee_id: string;
  name: string;
  reason: string;
  receipt_name: string[];
  receipt_filename: string[];
  currency: string;
}

interface ApiResponse {
  id: number;
  expense_date: string;
  expense_amount: number;
  receipt_name: string[];
  receipt_filename: string[];
}

const ViewExpense = () => {
  // !REDUX DATA
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const userId = loginData?.userId;

  // !VARIABLES
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string>("");
  const [approverData, setApproverData] = useState<Approvers[]>([]);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [receipts, setReceipts] = useState<ApiResponse[]>([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);
  const [ExpenseData, setExpenseData] = useState<ExpenseData>({
    expense_category_name: "",
    expense_date: "",
    expense_amount: "",
    firstName: "",
    lastName: "",
    profile_image: "",
    employee_id: "",
    name: "",
    reason: "",
    receipt_filename: [],
    receipt_name: [],
    currency: "",
  });

  // !FUNCTIONS
  const handleClose = () => {
    setOpen(false);
    navigate("/expense-management");
  };

  const handleReject = () => {
    rejectData();
  };

  const handleApproved = () => {
    approveData();
  };

  const handleView = (url: string) => {
    setSelectedFileUrl(url);
  };

  const closeModal = () => {
    setSelectedFileUrl(null);
  };

  // !LOCATION
  const id = location.state?.id;

  // !API CALLS
  useEffect(() => {
    fetchEditEmployeeExpense(id)
      .then((data) => {
        if (data.result) {
          setReceipts(data.result);
          setExpenseData({
            expense_category_name:
              data.result?.ExpenseCategory?.expense_category_name,
            expense_date: data.result.expense_date,
            expense_amount: data.result.expense_amount,
            firstName: data.result.User?.firstName,
            lastName: data.result.User?.lastName,
            profile_image: data.result.User?.profile_image,
            employee_id: data.result?.User?.employee_id,
            name: data.result.User?.Department?.name,
            reason: data.result.description,
            receipt_filename: data.result?.receipt_filename,
            receipt_name: data.result?.receipt_name,
            currency: data.result?.expense_currency,
          });
          setApproverData(data.result?.ExpenseRequestApprovals);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  const approveData = async () => {
    const status = "Approved";
    try {
      const response = await approveRejectApi(id, status);
      toast.success(response?.data?.message);
      setTimeout(() => {
        navigate("/expense-management");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response?.data.message);
    }
  };

  const rejectData = async () => {
    const status = "Rejected";
    try {
      const response = await approveRejectApi(id, status);
      toast.success(response?.data?.message);
      setTimeout(() => {
        navigate("/expense-management");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response?.data?.message);
    }
  };

  // !APPROVERS
  useEffect(() => {
    approverData?.map((approver) => {
      if (approver?.approval_id === userId) {
        setShowButtons(true);
        setStatus(approver.approval_status);
      }
    });
  }, [approverData]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {status === "approved"
              ? "Your Request has been Approved"
              : "Your Request has been Rejected"}
          </Typography>
        </Box>
      </Modal>

      <div className="bg-[#FBFBFB] font-[Poppins] pt-6 pb-12 px-[18.5px]">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/expense-management")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Expense Details
            </span>
          </div>

          {showButtons ? (
            <div className="flex flex-start gap-5">
              {status !== "Rejected" ? (
                <button
                  onClick={handleReject}
                  className="flex w-[148px] bg-[#B3261E] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg h-12 hover:bg-[#96150e]"
                >
                  <div className="text-[#fff] text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                    Reject
                  </div>
                </button>
              ) : (
                <></>
              )}
              {status !== "Approved" ? (
                <button
                  className="flex w-[138px] p-[13.18px,21px,14.82px,21px] bg-[#359349] justify-center items-center flex-[1,1,0] self-stretch rounded-lg text-white h-12 hover:bg-[#1f8735]"
                  onClick={handleApproved}
                >
                  <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                    Approve
                  </div>
                </button>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="p-6 bg-white rounded-lg mt-6 shadow-md">
          <h2 className="text-lg font-semibold mb-4">Expense Details</h2>
          <div
            className="bg-gradient-to-r p-4 rounded-md"
            style={{
              background:
                "linear-gradient(90deg, rgba(65, 101, 196, 0.05) 0%, rgba(142, 31, 210, 0.05) 100%)",
            }}
          >
            <div className="grid grid-cols-3 p-1 gap-4 border-b-[2px] border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-[Poppins] font-[500] text-[16px] ">
                  Employee Name & Id
                </strong>
                <br />{" "}
                <div className="flex flex-row gap-[7px] items-center">
                  <div className="h-[31px] w-[31px]">
                    <img
                      src={ExpenseData.profile_image || profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="font-['Poppins'] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      {ExpenseData?.firstName || "--"} {ExpenseData?.lastName}
                    </span>
                    <span className="font-['Poppins'] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      {ExpenseData?.employee_id || "--"}
                    </span>
                  </div>
                </div>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-[Poppins] font-[500] text-[16px] ">
                  Application Date
                </strong>
                <br />{" "}
                <span className="text-[#1D1A22] font-[500] text-[16px] ">
                  {moment(ExpenseData.expense_date).format("DD MMM YYYY") || "--"}
                </span>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-[Poppins] font-[500] text-[16px] ">
                  Amount
                </strong>
                <br />
                <span className="text-[#1D1A22] font-[500] text-[16px] ">
                  {" "}
                  {ExpenseData?.currency + " " + ExpenseData.expense_amount ||
                    "--"}
                </span>
              </p>
            </div>
            <div className="grid grid-cols-3 p-2 gap-4 border-b-[2px] border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-[Poppins] font-[500] text-[16px] ">
                  Declaration Type
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                  {ExpenseData.expense_category_name || "--"}
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-[Poppins] font-[500] text-[16px] ">
                  Department
                </strong>
                <br />
                <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                  {" "}
                  {ExpenseData?.name || "--"}
                </strong>
              </p>
            </div>
            <div className="grid grid-cols-1 p-2 gap-4 border-b-[2px] border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-[Poppins] font-[500] text-[16px] ">
                  Reason
                </strong>
                <br />
                <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                  {" "}
                  {ExpenseData?.reason || "--"}
                </strong>
              </p>
            </div>
            <div className="flex p-2 border-b-[2px] border-gray-200 w-full">
              <p className="w-full">
                <strong className="text-[#99A1B7] font-['Poppins'] font-[500] text-[16px] ">
                  Approval Status
                </strong>
                <br />{" "}
                <div className="grid grid-cols-3 gap-4">
                  {approverData?.map((approver) => (
                    <strong className="text-[#1D1A22] font-['Poppins'] font-[500] text-[16px] ">
                      {approver.role}-{" "}
                      <span
                        className={`py-1 px-1.5 rounded-sm h-6 text-[12px] ${
                          approver.approval_status === "Approved"
                            ? "bg-[#D4F8C7]"
                            : approver.approval_status === "Pending"
                            ? "bg-[#FFC0767A]"
                            : approver.approval_status === "Rejected"
                            ? "bg-[#F8716A7A]"
                            : ""
                        }`}
                      >
                        {approver.approval_status}
                      </span>
                    </strong>
                  ))}
                </div>
              </p>
            </div>
            <div className="p-2">
              <h1 className="text-[#99A1B7] font-[Poppins] font-[500] text-[16px] mb-4">
                Receipts
              </h1>
              <div className="grid grid-cols-3 gap-4">
                {ExpenseData?.receipt_filename?.map((url, index) => {
                  const fileName =
                    ExpenseData?.receipt_name &&
                    ExpenseData?.receipt_name[index]
                      ? ExpenseData?.receipt_name[index]
                      : `Receipt ${index + 1}`;
                  return (
                    <Receipt
                      key={index}
                      fileName={fileName}
                      fileSize={`${(Math.random() * 2 + 1)?.toFixed(2)} MB`}
                      onView={() => handleView(url)}
                    />
                  );
                })}

                {selectedFileUrl && (
                  <ModalViewer
                    isOpen={!!selectedFileUrl}
                    onRequestClose={closeModal}
                    fileUrl={selectedFileUrl}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewExpense;
