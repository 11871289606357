import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import right from "../../../image/chevron-right.png";
import { Box, TextField, MenuItem } from "@mui/material";
import remainingIcon from "../../../assets/remainingIcon.png";
import usedIcon from "../../../assets/usedIcon.png";
import profile from "../../../assets/Ellipse 580.png";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { api_key_work as api_key } from "../../../utils/helperData";
import toast from "react-hot-toast";

ChartJS.register(ArcElement, Tooltip);

interface LeaveType {
  name: string;
  total: number;
  used: number;
  remaining: number;
  userId: number;
  leaveTypeId: number;
}

interface LeaveTypeData {
  firstName: string;
  lastName: string;
  employee_id: string;
  profile_image: string;
}

export const EditLeaveBalance = () => {
  const navigate = useNavigate();
  const [leaves, setLeaves] = useState<LeaveType[]>([]);
  const [leavesdata, setLeavesData] = useState<LeaveTypeData>({
    firstName: "",
    lastName: "",
    employee_id: "",
    profile_image: "",
  });

  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    // Fetch the leave data from the API
    const fetchLeaveData = async () => {
      const access_token = localStorage.getItem("access_token");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API}/api/v1/leave-balance-details?user_id=${id}`,
          {
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );

        const fetchedLeaves: LeaveType[] =
          response.data?.result?.UserLeaves.map((leave: any) => ({
            name: leave?.LeaveType?.leave_type_name,
            total: leave?.allotted_leaves,
            used: leave?.used_leave,
            remaining: leave?.allotted_leaves - leave?.used_leave,
            userId: response.data.result.id,
            leaveTypeId: leave?.LeaveType?.id,
            firstName: response.data.result.firstName,
            lastName: response.data.result.lastName,
            employee_id: response.data.result.employee_id,
          }));
        setLeaves(fetchedLeaves);
        setLeavesData({
          firstName: response.data?.result?.firstName,
          lastName: response.data?.result?.lastName,
          employee_id: response.data?.result?.employee_id,
          profile_image: response.data?.result?.profile_image,
        });
      } catch (error) {
        console.error("Error fetching leave data:", error);
      }
    };

    fetchLeaveData();
  }, []);

  const handleInputChange = (index: number, field: string, value: number) => {
    const newLeaves = [...leaves];
    if (field === "used") {
      newLeaves[index].used = value;
      newLeaves[index].remaining = newLeaves[index].total - value;
    } else if (field === "total") {
      newLeaves[index].total = value;
      newLeaves[index].remaining = value - newLeaves[index].used;
    }
    setLeaves(newLeaves);
  };

  const handleUpdate = async () => {
    const access_token = localStorage.getItem("access_token");
    try {
      for (const leave of leaves) {
        await axios.put(
          `${process.env.REACT_APP_BASE_API}/api/v1/leave-balance/${leave?.userId}`,
          {
            allotted_leaves: leave.total,
            used_leave: leave.used,
            user_id: leave.userId,
            leave_type_id: leave.leaveTypeId,
          },
          {
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
      }
      toast.success("Leave balances updated successfully");
    } catch (error) {
      console.error("Error updating leave balances:", error);
    }
  };

  const handleOpen = () => {
    handleUpdate();
  };

  const leaveData = {
    labels: ["Used Leaves", "Remaining Leaves"],
    datasets: [
      {
        data: [
          leaves.reduce((acc, cur) => acc + cur.used, 0),
          leaves.reduce((acc, cur) => acc + cur.remaining, 0),
        ],
        backgroundColor: ["#FD9E08", "#239B5B"],
        hoverBackgroundColor: ["#FD9E08", "#239B5B"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="px-4 pt-6 pb-12 min-h-[90vh] bg-[#F9F9F9]">
      <div className=" flex flex-col gap-2 w-full ">
        <div className="px-0.5 mb-6 flex flex-row justify-between w-full">
          <div className="flex flex-row gap-[13px] justify-center items-start">
            <button onClick={() => navigate("/leave-balance")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-['Poppins'] text-xl font-semibold leading-normal">
              Edit Employee Leave Balance Details
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate("/leave-balance")}
              className="flex w-[148px] borderButton p-[13.18px,50px,14.82px,51px]  justify-center items-center self-stretch rounded-lg h-12"
              //onClick={(event) => handleCurrentPageChange("table", event)}
            >
              <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <button
              type="submit"
              // form="form"
              className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
              onClick={handleOpen}
            >
              <div
                //onClick={handleOpen}
                className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
              >
                Update
              </div>
            </button>
          </div>
        </div>
        <div className="h-28 flex flex-row items-center justify-between rounded-md w-full bg-white shadow-lg">
          <div className="flex flex-col h-[72px] items-start justify-center py-5 px-6 gap-2.5">
            <span className="h-5 font-['Inter'] font-medium text-sm text-[#49454F]">
              Employee Name & ID
            </span>
            <div className="flex flex-row gap-3.5">
              <div className="h-11 w-11">
                <img
                  src={leavesdata.profile_image || profile}
                  alt=""
                  className="h-full w-full rounded-full"
                />
              </div>
              <div className="flex flex-col">
                <span className="font-['Poppins'] font-medium text-sm text-[#1D1A22]">
                  {leavesdata?.firstName} {leavesdata?.lastName}
                </span>
                <span className="font-['Poppins'] font-medium text-sm text-[#605D66]">
                  {leavesdata?.employee_id}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-2.5 justify-between">
          <div className="flex flex-col gap-2 p-4 rounded-md shadow-lg bg-white">
            <div className="flex flex-col px-4 pb-4">
              <span className="text-[#464E5F] font-['Poppins'] text-lg font-semibold leading-normal">
                Leave Balance
              </span>
              <span className="text-[#B5B5C3] font-['Poppins'] font-medium text-[10.67px] leading-[20.44px]">
                Employee Leave Balance Details
              </span>
            </div>
            <div className="">
              <Doughnut data={leaveData} options={options} />
            </div>
            <div className="flex flex-col gap-6 px-4">
              <div className="flex flex-row items-center">
                <img src={usedIcon} className="w-10 h-10" />
                <div className="flex flex-col px-2 gp-0.25">
                  <span className="h-6 text-[#322F37] font-['Poppins'] font-semibold text-base">
                    {leaves.reduce((acc, cur) => acc + cur.used, 0)}
                  </span>
                  <span className="h-4 text-[#938F99] font-['Poppins'] leading-6 text-[10.67px] font-medium">
                    Used Leaves
                  </span>
                </div>
              </div>
              <div className="flex flex-row">
                <img src={remainingIcon} className="w-10 h-10" />
                <div className="flex flex-col px-2 gap-0.25">
                  <span className="h-6 text-[#322F37] font-['Poppins'] font-semibold text-base">
                    {leaves.reduce((acc, cur) => acc + cur.remaining, 0)}
                  </span>
                  <span className="h-4 text-[#938F99] font-['Poppins'] leading-6 text-[10.67px] font-medium">
                    Remaining Leaves
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[805px] rounded-md shadow-lg bg-white">
            <div className="flex flex-col px-10 py-5">
              {leaves.map((leave, index) => (
                <div className="container mt-1 mx-auto p-1" key={index}>
                  <div className="text-[#262626] font-[500] text-[14px] ml-4 font-['Poppins']">
                    {leave.name} - {leave.total}
                  </div>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="grid grid-cols-3 gap-4">
                      <TextField
                        required
                        id={`outlined-total-${index}`}
                        label="Total Leaves"
                        value={
                          leave.total === Infinity ? "Unlimited" : leave.total
                        }
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "total",
                            Number(e.target.value)
                          )
                        }
                        disabled={leave.total === Infinity}
                        name={`totalLeaves-${index}`}
                      />
                      <TextField
                        required
                        id={`outlined-used-${index}`}
                        select
                        label="Used Leaves"
                        value={leave.used}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "used",
                            parseInt(e.target.value, 10)
                          )
                        }
                        name={`usedLeaves-${index}`}
                      >
                        {Array.from(
                          {
                            length:
                              leave.total === Infinity ? 0 : leave.total + 1,
                          },
                          (_, i) => (
                            <MenuItem key={i} value={i}>
                              {i}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                      <TextField
                        required
                        id={`outlined-remaining-${index}`}
                        label="Remaining Leaves"
                        value={
                          leave.remaining === Infinity
                            ? "Unlimited"
                            : leave.remaining
                        }
                        disabled
                        name={`remainingLeaves-${index}`}
                      />
                    </div>
                  </Box>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//export default EditLeaveBalance;
