import React, { useState } from "react";

import profile from "../../assets/Ellipse 580.png";

import TablePaginationDemo from "../common/Pagenation";

function ProjectTaskAndResources() {
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [page, setPage] = useState(1);

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };
  return (
    <div className="pt-4 flex flex-col gap-4">
      <div className="flex flex-col gap-3">
        <span className="font-[Poppins] font-semibold text-base text-[#1D1A22]">
          Tasks & Resources
        </span>
        <div className="flex flex-col gap-3">
          <div className="p-5 card-shadow rounded-md border-l-4 border-[#AD8CF1] flex flex-col gap-5 bg-white">
            <div className="flex justify-between items-center">
              <span className="font-[Poppins] font-medium text-base text-[#1D1A22] w-[74%]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
              </span>
              <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                34hrs
              </span>
            </div>
            <div className="grid grid-cols-3 gap-x-5 gap-y-2.5 custom:grid-cols-2">
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
            </div>
          </div>
          <div className="p-5 card-shadow rounded-md border-l-4 border-[#AD8CF1] flex flex-col gap-5 bg-white">
            <div className="flex justify-between items-center">
              <span className="font-[Poppins] font-medium text-base text-[#1D1A22] w-[74%]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
              </span>
              <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                34hrs
              </span>
            </div>
            <div className="grid grid-cols-3 gap-x-5 gap-y-2.5 custom:grid-cols-2">
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
            </div>
          </div>
          <div className="p-5 card-shadow rounded-md border-l-4 border-[#AD8CF1] flex flex-col gap-5 bg-white">
            <div className="flex justify-between items-center">
              <span className="font-[Poppins] font-medium text-base text-[#1D1A22] w-[74%]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
              </span>
              <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                34hrs
              </span>
            </div>
            <div className="grid grid-cols-3 gap-x-5 gap-y-2.5 custom:grid-cols-2">
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
              <div className="py-[11px] pl-5 pr-2.5 rounded-md border border-[#EDEDED] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      Brooklyn Simmons
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  34hrs
                </span>
              </div>
            </div>
          </div>
        </div>
        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={1}
            numOfItems={10}
          />
        )}
      </div>
    </div>
  );
}

export default ProjectTaskAndResources;
