import React, { useCallback, ChangeEvent, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { debounce } from "lodash";
import * as XLSX from "xlsx";
import { Chip } from "@material-ui/core";

import maskPlus from "../../image/Mask group.svg";
import filter from "../../assets/filter.svg";
import download from "../../assets/download_new.svg";
import view from "../../assets/Crud_Icons/view.svg";
import edit from "../../assets/Crud_Icons/edit.svg";

import Search from "../SearchBar/Search";
import Filter from "../Filter/Filter";
import TablePaginationDemo from "../common/Pagenation";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import { Fetch_Announcement_List } from "../common/services/Announcements";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface DepartmentUnit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface RowData {
  id: number;
  title: string;
  status: string;
  group_id: Number;
  date: string;
  description: string;
  Group: {
    name: string;
  };
  AnnouncementDepartments: {
    department_id: number;
    Department: {
      id: number;
      name: string;
    };
  }[];
}

interface Column {
  id: string;
  displayName: string;
}

interface AnnouncementData {
  [key: string]: any;
}

function Announcements() {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<DepartmentUnit[]>();
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [departmentSelect, setDepartmentSelect] = useState(false);
  const [businessSelect, setBusinessSelect] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);
  const [reportData, setReportData] = useState<AnnouncementData[]>([]);
  const [selectedDepartmentUnit, setSelectedDepartmentUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [tempSelectedBusinessUnit, setTempSelectedBusinessUnit] = useState<
    Business_Unit | DepartmentUnit
  >();
  const [tempSelectedDepartmentUnit, setTempSelectedDepartmentUnit] = useState<
    Business_Unit | DepartmentUnit
  >();

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    }, 100),
    []
  );

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const handleCalanderClick = () => {};
  const handleDateChange = () => {};

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const handleBusinessUnitSelect = (
    option: Business_Unit | DepartmentUnit
  ) => {
    setTempSelectedBusinessUnit(option);
  };

  const handleDepartmentUnitSelect = (
    option: Business_Unit | DepartmentUnit
  ) => {
    setTempSelectedDepartmentUnit(option);
  };

  const handleFilterApply = () => {
    setSelectedBusinessUnit(tempSelectedBusinessUnit);
    setSelectedDepartmentUnit(tempSelectedDepartmentUnit);
    tempSelectedDepartmentUnit && setDepartmentSelect(true);
    tempSelectedBusinessUnit && setBusinessSelect(true);
    setIsOpen(false);
  };

  const handleDeleteDepartment = () => {
    setDepartmentSelect(false);
    setTempSelectedDepartmentUnit(undefined);
    setSelectedDepartmentUnit(undefined);
    fetchData();
  };

  const handleDeleteBusiness = () => {
    setBusinessSelect(false);
    setTempSelectedBusinessUnit(undefined);
    setSelectedBusinessUnit(undefined);
    fetchData();
  };

  const handleDownload = () => {
    if (dataFetched) {
      // Generate worksheet data from reportData
      const worksheetData = [
        columns.map((col) => col.displayName), // Header row
        ...reportData.map((row) =>
          columns.map((col) => row[col.id]?.toString() || "")
        ), // Data rows
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Apply formatting to the header row (make bold)
      const headerRange = XLSX.utils.decode_range(worksheet["!ref"] || "");
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
        if (!worksheet[cellAddress]) continue;
        worksheet[cellAddress].s = {
          font: { bold: true }, // Make headers bold
          alignment: { horizontal: "center", vertical: "center" }, // Center headers
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
          fill: {
            fgColor: { rgb: "E0EBF5" }, // Light background color
          },
        };
      }

      // Auto-width columns based on their content
      worksheet["!cols"] = columns.map(() => ({ wch: 20 })); // Default column width

      // Add cell borders to all data cells
      for (let R = headerRange.s.r + 1; R <= headerRange.e.r; ++R) {
        for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
          if (!worksheet[cellAddress]) continue;
          worksheet[cellAddress].s = {
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
            alignment: { horizontal: "left", vertical: "center" }, // Align text properly
          };
        }
      }

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Announcement Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "announcement_data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.warn("Data has not been fetched yet.");
    }
  };

  // Session Handling
  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {
      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await Fetch_Announcement_List(
        customer_id,
        numOfItems,
        page,
        Number(selectedBusinessUnit?.id),
        Number(selectedDepartmentUnit?.id),
        searchTerm
      );
      if (response.status === 200) {
        setTableData(response.data.result.rows);
        setCount(response.data.result.count);

        const fetchedData: AnnouncementData[] = response.data.result.rows;
        const columnMapping: { [key: string]: string } = {
          id: "ID",
          title: "Announcment Title",
          description: "Description",
          "Group.name": "Business Unit",
          departments: "Departments",
        };

        const filteredData = fetchedData?.map((row) => {
          const filteredRow: Partial<AnnouncementData> = {};

          Object.keys(columnMapping).forEach((key) => {
            const keys = key.split(".");

            if (keys.length === 1) {
              if (key === "departments") {
                filteredRow[key] =
                  row.AnnouncementDepartments?.map(
                    (dept: any) => dept.Department.name
                  ).join(", ") || "No Departments";
              } else if (row.hasOwnProperty(keys[0])) {
                filteredRow[keys[0]] = row[keys[0]];
              }
            } else if (keys.length === 2) {
              const parentKey = keys[0];
              const childKey = keys[1];
              if (row[parentKey] && row[parentKey].hasOwnProperty(childKey)) {
                // Store the nested value under the child key
                filteredRow[key] = row[parentKey][childKey];
              } else {
                filteredRow[key] = null; // Set to null if the nested value doesn't exist
              }
            }
          });

          return filteredRow;
        });

        setReportData(filteredData);

        const cols: Column[] = Object.keys(columnMapping).map((key) => ({
          id: key,
          displayName: columnMapping[key],
        }));
        setColumns(cols);

        setDataFetched(true);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    fetchData();
  }, [
    searchTerm,
    page,
    numOfItems,
    selectedBusinessUnit?.id,
    selectedDepartmentUnit?.id,
    startSession,
    endSession,
  ]);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, [customer_id, navigate, dispatch]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchDepartment();
  }, [customer_id, navigate, dispatch]);

  return (
    <div className="min-h-[90vh] px-4 pt-6 pb-12 bg-[#F9F9F9]">
      <div className="flex flex-col gap-12">
        <div className="flex justify-between">
          <div className="flex flex-row gap-[2px] justify-center items-center">
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Announcements
            </span>
          </div>
          <div>
            <button
              onClick={() => navigate("/add-announcement")}
              className="flex items-center font-['Poppins'] py-2 font-[600] text-[14px] px-4 buttoncss text-white rounded-lg"
            >
              <img className="w-5 h-5 mr-2" src={maskPlus} alt="plus icon" />
              Add Announcements
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="h-[40px]">
            <div className="h-full flex justify-between items-center">
              <Search
                onChange={updateSearchTerm}
                label="Search with Announcement"
              />
              <div className="h-full flex items-center gap-5">
                <div className="h-full flex items-center gap-2">
                  {departmentSelect && (
                    <Chip
                      label={selectedDepartmentUnit?.name}
                      onDelete={handleDeleteDepartment}
                      size="small"
                    />
                  )}
                  {businessSelect && (
                    <Chip
                      label={selectedBusinessUnit?.name}
                      onDelete={handleDeleteBusiness}
                      size="small"
                    />
                  )}
                </div>
                <button
                  className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white hover:bg-gray-100"
                  onClick={() => setIsOpen(true)}
                >
                  <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                    Filter
                  </span>
                </button>
                <div className="h-full">
                  <button
                    className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text"
                    onClick={handleDownload}
                  >
                    <img
                      src={download}
                      alt="download"
                      className="h-[15px] w-[15px]"
                    />
                    <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                      Download Report
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2.5">
            <div className="flex justify-end gap-8">
              <div className="flex flex-row justify-between gap-1 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                >
                  <circle
                    cx="3.99537"
                    cy="3.74537"
                    r="3.49537"
                    fill="#00CBA0"
                  />
                </svg>
                <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                  Published
                </span>
              </div>

              <div className="flex flex-row justify-between gap-1 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                >
                  <circle
                    cx="3.99537"
                    cy="3.74537"
                    r="3.49537"
                    fill="#FF9950"
                  />
                </svg>
                <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
                  Scheduled
                </span>
              </div>
            </div>
            <div className="overflow-x-auto overflow-y-auto shadow-sm">
              <table className="w-full min-w-max text-left table-auto bg-white">
                <thead className="rounded-t-[3px] border border-[#E0E0E0]">
                  <tr className="h-11 w-full">
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-[32px] w-[20%]">
                      Title
                    </th>
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 w-[25%]">
                      Description
                    </th>
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 w-[20%]">
                      Business Unit
                    </th>
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 w-[25%]">
                      Departments
                    </th>
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pr-5 flex justify-end">
                      Action
                    </th>
                  </tr>
                </thead>
                {tableData?.map((item) => (
                  <tbody className="border border-[#EBEBEB]">
                    <tr className="h-12 border-t-0 border-l-0 border-r-0 border-b border-[#EBEBEB] hover:bg-gray-100">
                      <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] pl-5">
                        <div className="flex gap-[9px] items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 8 8"
                            fill="none"
                          >
                            <circle
                              cx="3.99537"
                              cy="3.74537"
                              r="3.49537"
                              fill={
                                item.status === "published"
                                  ? "#00CBA0"
                                  : item.status === "scheduled"
                                  ? "#FF9950"
                                  : "#D3D3D3"
                              }
                            />
                          </svg>
                          <span>{item.title}</span>
                        </div>
                      </td>
                      <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F]">
                        {item.description?.length > 28
                          ? `${item.description?.substring(0, 28)}...`
                          : item?.description}
                      </td>
                      <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F]">
                        {item?.Group?.name}
                      </td>
                      <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F]">
                        {item.AnnouncementDepartments.map(
                          (dept) => dept.Department.name
                        ).join(", ").length > 28
                          ? `${item.AnnouncementDepartments.map(
                              (dept) => dept.Department.name
                            )
                              .join(", ")
                              .substring(0, 28)}...`
                          : item.AnnouncementDepartments.map(
                              (dept) => dept.Department.name
                            ).join(", ")}
                      </td>
                      <td className="pr-5">
                        <div className="flex justify-end items-center gap-2">
                          <img
                            className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                            src={view}
                            onClick={() =>
                              navigate("/view-announcement", {
                                state: { id: item.id },
                              })
                            }
                            alt="View"
                          />
                          <img
                            className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                            onClick={() =>
                              navigate(`/edit-announcement`, {
                                state: { id: item.id },
                              })
                            }
                            src={edit}
                            alt="Edit"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
              {tableData.length === 0 ? (
                <div className="w-full h-10 px-2.5 py-1.5 flex justify-center items-center bg-white shadow-sm font-[Poppins] text-base text-[#1D1A22] border border-[#EBEBEB]">
                  Data not Found!
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div>
            {count >= 10 && (
              <TablePaginationDemo
                count={count}
                handleItemsChange={handleNumberOfPages}
                handlePageChange={handlePageChange}
                currentPage={1}
                numOfItems={10}
              />
            )}
          </div>
        </div>
      </div>
      <Filter
        isDate={false}
        isOther
        heading="Announcements Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </div>
  );
}

export default Announcements;
