import React, { useEffect } from "react";
import { Box, Tab } from "@mui/material";
import { tabsClasses } from '@mui/material/Tabs';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import Leave from "./Leave/Leave";
import { WFH } from "../WorkFromHome/WFH";
import Holiday from "./Holiday/Holiday";
import LeaveBalance from "./LeaveBalance/LeaveBalance";
import LeaveType from "./LeaveType/LeaveType";
import "../EmployeeManagement/employee.css";
import maskPlus from "../../image/Mask group.svg";
import WFHBalance from "../WorkFromHome/WFHBalance/WFHBalance";
import WFHTypes from "../WorkFromHome/WFHTypes/WFHTypes";

export const LeaveAndHoliday = () => {
  const history = useNavigate();
  const [value, setValue] = React.useState<string>("1");

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/leave-holiday-management") {
      setValue("1");
    } else if (location.pathname === "/work-from-home") {
      setValue("2");
    } else if (location.pathname === "/holiday") {
      setValue("3");
    } else if (location.pathname === "/leave-balance") {
      setValue("4");
    } else if (location.pathname === "/leave-type") {
      setValue("5");
    } else if (location.pathname === "/wfh-balance") {
      setValue("6");
    } else if (location.pathname === "/wfh-type") {
      setValue("7");
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === "1") {
      history("/leave-holiday-management");
    } else if (newValue === "2") {
      history("/work-from-home");
    } else if (newValue === "3") {
      history("/holiday");
    } else if (newValue === "4") {
      history("/leave-balance");
    } else if (newValue === "5") {
      history("/leave-type");
    } else if (newValue === "6") {
      history("/wfh-balance");
    } else if (newValue === "7") {
      history("/wfh-type");
    }
  };
  return (
    <>
      <div className="px-4 pt-6 pb-12 bg-[#F9F9F9] min-h-[90vh]">
        <div className="">
          <Box>
            <TabContext value={value}>
              <div className="flex justify-between flex-wrap gap-y-4">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    sx={{
                      "& .MuiTab-root": {
                        color: "#89888E",
                        fontFamily: "poppins",
                      },
                      "& .Mui-selected": {
                        color: "#000000 !important",
                        fontFamily: "poppins",
                      },
                      [`& .${tabsClasses.scrollButtons}`]: {
                        "&.Mui-disabled": { opacity: 0.3 },
                      },
                      [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                      },
                    }}
                    variant="scrollable"
                    scrollButtons
                  >
                    <Tab className="text-[#89888E]" label="Leave" value="1" />
                    <Tab label="Work From Home" value="2" />
                    <Tab label="Holiday" value="3" />
                    <Tab label="Leave Balance" value="4" />
                    <Tab label="Leave Types" value="5" />
                    <Tab label="WFH Balance" value="6" />
                    <Tab label="WFH Types" value="7" />
                  </TabList>
                </Box>

                {value === "1" ? (
                  <div className="ml-auto">
                    <button
                      onClick={() => history("/add-leave")}
                      className="flex items-center font-[600] text-[14px] py-2 px-4 buttoncss text-white rounded-lg"
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Employee Leave
                    </button>
                  </div>
                ) : value === "2" ? (
                  <div className="ml-auto">
                    <button
                      onClick={() => history("/add-work-from-home")}
                      className="flex items-center font-[600] text-[14px] py-2 px-4 buttoncss text-white rounded-lg"
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add WFH
                    </button>
                  </div>
                ) : value === "3" ? (
                  <div className="ml-auto">
                    <button
                      onClick={() => history("/add-holiday")}
                      className="flex items-center font-[600] text-[14px] py-2 px-4 buttoncss text-white rounded-lg"
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Holiday
                    </button>
                  </div>
                ) : value === "4" ? (
                  <div></div>
                ) : value === "5" ? (
                  <div className="ml-auto">
                    <button
                      onClick={() => history("/add-leave-type")}
                      className="flex items-center font-[600] text-[14px] py-2 px-4 buttoncss text-white rounded-lg"
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Leave Type
                    </button>
                  </div>
                ) : value === "6" ? (
                  <></>
                ) : (
                  <div className="ml-auto">
                    <button
                      onClick={() => history("/add-wfh-type")}
                      className="flex items-center font-[600] text-[14px] py-2 px-4 buttoncss text-white rounded-lg"
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add WFH Type
                    </button>
                  </div>
                )}
              </div>
              <TabPanel className="tabpanel" value="1">
                <Leave />
              </TabPanel>
              <TabPanel className="tabpanel" value="2">
                <WFH />
              </TabPanel>
              <TabPanel className="tabpanel" value="3">
                <Holiday />
              </TabPanel>
              <TabPanel className="tabpanel" value="4">
                <LeaveBalance />
              </TabPanel>
              <TabPanel className="tabpanel" value="5">
                <LeaveType />
              </TabPanel>
              <TabPanel className="tabpanel" value="6">
                <WFHBalance />
              </TabPanel>
              <TabPanel className="tabpanel" value="7">
                <WFHTypes />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </>
  );
};
