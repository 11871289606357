import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { CircularProgress } from '@mui/material';

import profile from "../../assets/Ellipse 580.png";

import TablePaginationDemo from "../common/Pagenation";
import { Fetch_Project_User_List } from "../common/services/ProjectReport";

interface ProjectandResources {
  name: string;
  duration: number;
}

function ProjectandResources() {
  const location = useLocation();
  const id = location.state?.id;
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<ProjectandResources[]>([]);

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  useEffect(() => {
    setLoading(true);
    Fetch_Project_User_List(id)
      .then((data) => {
        if (data.result) {
          setTableData(data.result.users);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [id]);

  return (
    <div className="pt-4 flex flex-col gap-4">
      <div className="flex flex-col gap-3">
        <span className="font-[Poppins] font-semibold text-base text-[#1D1A22]">
          Resources
        </span>
        {loading ? (
          <div className="w-full flex justify-center items-center h-10">
            <CircularProgress/> 
          </div>
        ) : (
          <div className="grid grid-cols-3 gap-2.5 custom:grid-cols-2">
            {tableData?.map((item) => (
              <div className="py-[11px] pl-5 pr-2.5 card-shadow rounded-md border-l-4 border-[#AD8CF1] flex justify-between items-center bg-white">
                <div className="h-[40px] flex items-center gap-2.5">
                  <div className="h-full w-[40px] rounded-full">
                    <img
                      src={profile}
                      alt="profile"
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="h-full flex flex-col justify-center">
                    <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                      {item?.name || "--"}
                    </span>
                    <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                      EMP0062
                    </span>
                  </div>
                </div>
                <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                  {item?.duration} Hrs.
                </span>
              </div>
            ))}
          </div>
        )}
        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={1}
            numOfItems={10}
          />
        )}
      </div>
    </div>
  );
}

export default ProjectandResources;
