import { TextField, Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Fetch_Employee_List_List_Management } from "../../redux/actions/Employee/Employee";
import {
  Avatar,
  Card,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import profile from "../../assets/Ellipse 580.png";

interface Employee {
  id: string;
  firstName: string;
  lastName: string;
  employee_id: string;
  profile_image: string;
}

interface EmployeeSearchProps {
  onEmployeeSelect: (employeeIds: string[]) => void;
  onEmployeeSelectId: (user_Ids: string[]) => void;
}

const EmployeeSearch: React.FC<EmployeeSearchProps> = ({
  onEmployeeSelect,
  onEmployeeSelectId,
}) => {
  const employee = useSelector((state: any) => state.emplist?.EmployeeData);
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const [searchQuery, setSearchQuery] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const dispatch = useDispatch();

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  useEffect(() => {
    dispatch<any>(
      Fetch_Employee_List_List_Management(customer_id, 200, 1, searchQuery)
    );
  }, [dispatch, customer_id, searchQuery]);

  const filteredEmployees = employee.filter(
    (emp: { employee_id: string; firstName: string; lastName: string }) =>
      emp.employee_id?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      emp.firstName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      emp.lastName?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setIsDropdownOpen(true);
  };

  const handleOptionClick = (employee: Employee) => {
    if (!selectedEmployees.some((emp) => emp.id === employee.id)) {
      const newSelectedEmployees = [...selectedEmployees, employee];
      setSelectedEmployees(newSelectedEmployees);
      onEmployeeSelect(newSelectedEmployees.map((emp) => emp.employee_id));
      onEmployeeSelectId(newSelectedEmployees.map((emp) => emp.id));
    }
    setSearchQuery("");
    setIsDropdownOpen(false);
  };

  const handleDelete = (employeeToDelete: Employee) => () => {
    const newSelectedEmployees = selectedEmployees.filter(
      (emp) => emp.id !== employeeToDelete.id
    );
    setSelectedEmployees(newSelectedEmployees);
    onEmployeeSelect(newSelectedEmployees.map((emp) => emp.employee_id));
    onEmployeeSelectId(newSelectedEmployees.map((emp) => emp.id));
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <TextField
        fullWidth
        required
        id="outlined-required"
        label="Policy Approvers"
        name="empId"
        placeholder="Search with Employee name & ID"
        onFocus={() => setIsDropdownOpen(true)}
        onChange={handleInputChange}
        value={searchQuery}
      />
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute mt-2 w-full p-4 bg-white shadow-lg rounded-lg max-h-52 overflow-y-auto z-[999]"
        >
          {filteredEmployees.length > 0 ? (
            filteredEmployees.map((employee: Employee) => (
              <div
                key={employee.id}
                className="flex items-center p-2 mb-2 bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-lg"
                onClick={() => handleOptionClick(employee)}
              >
                <div>
                  <p className="font-semibold">
                    {employee.firstName} {employee.lastName}
                  </p>
                  <p className="text-gray-600">{employee.employee_id}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-600">No results found</div>
          )}
        </div>
      )}
      <Box mt={2}>
        <Card key={employee.id} className="mb-2">
          {selectedEmployees.map((employee: Employee) => (
            <CardHeader
              title={
                <Typography variant="h6">
                  {employee.firstName} {employee.lastName}
                </Typography>
              }
              action={
                <IconButton onClick={handleDelete(employee)}>X</IconButton>
              }
            />
          ))}
        </Card>
      </Box>
    </div>
  );
};

export default EmployeeSearch;
