import React, { useState, useEffect, useCallback, ChangeEvent } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { debounce } from "lodash";

import report from "../../assets/report.svg";
import right from "../../image/chevron-right.png";
import filter from "../../assets/filter.svg";

import Search from "../SearchBar/Search";
import ProjectAndTask from "./ProjectAndTask";
import ProjectandResources from "./ProjectandResources";
import ProjectTaskAndResources from "./ProjectTaskAndResources";
import { Fetch_Project_Task_List } from "../common/services/ProjectReport";

import "./effort.css";
import moment from "moment";

interface ProjectDetails {
  project_name: string;
  taskCount: string;
  resourceCount: string;
  start_date: string;
  end_date: string;
}

function ViewDetails() {
  const history = useNavigate();
  const [value, setValue] = useState<string>("1");
  const [projectDetails, setProjectDetails] = useState<ProjectDetails[]>([]);

  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    Fetch_Project_Task_List(id)
      .then((data) => {
        if (data.result) {
          setProjectDetails(data.result.project);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (location.pathname === "/project-task") {
      setValue("1");
    } else if (location.pathname === "/project-resources") {
      setValue("2");
    } else if (location.pathname === "/project-task-resources") setValue("3");
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === "1") {
      history("/project-task", { state: { id } });
    } else if (newValue === "2") {
      history("/project-resources", { state: { id } });
    } else if (newValue === "3")
      history("/project-task-resources", { state: { id } });
  };

  const getIndicatorClass = () => {
    switch (localStorage.getItem("tabValue") || "1") {
      case "1":
        return "indicator";
      case "2":
        return "indicator";
      case "3":
        return "indicator";
      default:
        return "indicator";
    }
  };

  const handleSave = () => {};

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      //   setSearchTerm(event.target.value);
    }, 100),
    []
  );

  return (
    <>
      <div className="px-4 pt-6 pb-12 min-h-[90vh] bg-[#F9F9F9]">
        <div className="">
          <div className="flex flex-col gap-9">
            <div className="flex justify-between">
              <div className="flex flex-row gap-[13px] justify-center items-center">
                <button onClick={() => history("/project-effort-report")}>
                  <img
                    src={right}
                    alt="right arrow sign"
                    className="h-6 w-6 shrink-0 bg-black rounded-full"
                  />
                </button>
                <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                  Back to Projects
                </span>
              </div>
              <button className="px-[19px] py-[11px] rounded-lg buttoncss">
                <div className="flex justify-between items-center gap-[7px]">
                  <img src={report} alt="report" />
                  <span className="font-[Poppins] font-semibold text-base text-white">
                    Generate Report
                  </span>
                </div>
              </button>
            </div>
            <div className="h-[40px]">
              <div className="h-full flex justify-between items-center">
                <Search
                  onChange={updateSearchTerm}
                  label={
                    value === "1"
                      ? "Search with task name"
                      : value === "2"
                      ? "Search with Employee name"
                      : value === "3"
                      ? "Search with employee & task name"
                      : "Search"
                  }
                />
                <div className="h-full flex items-center gap-5">
                  {/* <div className="h-full flex items-center gap-2">
                  {departmentSelect && (
                    <Chip
                      label={selectedDepartmentUnit?.name}
                      onDelete={handleDeleteDepartment}
                      size="small"
                    />
                  )}
                  {businessSelect && (
                    <Chip
                      label={selectedBusinessUnit?.name}
                      onDelete={handleDeleteBusiness}
                      size="small"
                    />
                  )}
                </div> */}
                  <button
                    className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white hover:bg-gray-100"
                    //   onClick={() => setIsOpen(true)}
                  >
                    <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                    <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                      Filter
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2.5">
          <Box>
            <TabContext value={value}>
              <div className="flex justify-between">
                <Box
                  sx={{
                    backgroundColor: "#EDEDED",
                    borderRadius: "6px",
                    padding: "5px",
                    height: "42px",
                  }}
                >
                  {" "}
                  {/* Updated background color */}
                  <TabList
                    TabIndicatorProps={{ className: getIndicatorClass() }}
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    sx={{
                      "& .MuiTab-root": {
                        color: "#79747E",
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        height: "32px",
                        minHeight: "32px",
                        transition: "all 0.2s ease-in-out",
                        textTransform: "none",
                      },
                      "& .Mui-selected": {
                        color: "#000000 !important",
                        fontFamily: "Inter",
                        backgroundColor: "#fff",
                        borderRadius: "4px",
                        boxShadow:
                          "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);",
                        transition: "all 0.2s ease-in-out",
                      },
                    }}
                  >
                    <Tab label="Project & Task" value="1" disableRipple />
                    <Tab label="Project & Resources" value="2" disableRipple />
                    <Tab
                      label="Project, Task & Resources"
                      value="3"
                      disableRipple
                    />
                  </TabList>
                </Box>
              </div>
              <div className="mt-4 px-6 py-3 rounded-[10px] bg-white border border-[#CDCDCD] shadow-custom flex flex-col gap-4">
                <div className="flex flex-col">
                  <span className="font-[Poppins] font-medium text-[24px] leading-[36px] text-black">
                    {projectDetails[0]?.project_name}
                  </span>
                  <span className="font-[Poppins] font-medium text-[12px] leading-[16.24px] text-[#79747E]"></span>
                </div>
                <div className="w-full flex justify-start items-center gap-6">
                  <div className="px-5 py-2 border border-[#EDEDED] rounded-md flex flex-col">
                    <span className="font-[Poppins] font-normal text-base text-[#938F99]">
                      Start Date
                    </span>
                    <span className="font-[Poppins] font-medium text-[20px] leading-[30px] text-black">
                      {moment(projectDetails[0]?.start_date).format(
                        "DD MMM YYYY"
                      )}
                    </span>
                  </div>
                  <div className="px-5 py-2 border border-[#EDEDED] rounded-md flex flex-col">
                    <span className="font-[Poppins] font-normal text-base text-[#938F99]">
                      End Date
                    </span>
                    <span className="font-[Poppins] font-medium text-[20px] leading-[30px] text-black">
                      {moment(projectDetails[0]?.end_date).format(
                        "DD MMM YYYY"
                      ) || "--"}
                    </span>
                  </div>
                  <div className="px-5 py-2 border border-[#EDEDED] rounded-md flex flex-col">
                    <span className="font-[Poppins] font-normal text-[#938F99]">
                      Total Tasks
                    </span>
                    <span className="font-[Poppins] font-medium text-[20px] leading-[30px] text-black">
                      {projectDetails[0]?.taskCount}
                    </span>
                  </div>
                  <div className="px-5 py-2 border border-[#EDEDED] rounded-md flex flex-col">
                    <span className="font-[Poppins] font-normal text-[#938F99]">
                      Total Resources
                    </span>
                    <span className="font-[Poppins] font-medium text-[20px] leading-[30px] text-black">
                      {projectDetails[0]?.resourceCount}
                    </span>
                  </div>
                </div>
              </div>
              <TabPanel className="tabpanel" value="1">
                <ProjectAndTask />
              </TabPanel>
              <TabPanel className="tabpanel" value="2">
                <ProjectandResources />
              </TabPanel>
              <TabPanel className="tabpanel" value="3">
                <ProjectTaskAndResources />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </>
  );
}

export default ViewDetails;
