import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import right from "../../image/chevron-right.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import upload from "../../assets/plus 1.png";
import {
  Button,
  Modal,
  Box,
  Tabs,
  Tab,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import {
  createResourcesApi,
  Fetch_Resources_List,
} from "../common/services/Project";
import toast from "react-hot-toast";
import del from "../../assets/Crud_Icons/delete.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import ModalResources from "./Modal";
import Search from "../SearchBar/Search";
import TablePaginationDemo from "../common/Pagenation";
import ResourceSearch from "./ResourceSearch";
import EmployeeSearch from "../EmployeeManagement/EmployeeSearch";
import ManagerSearch from "./ManagerSearch";

interface ProjectResource {
  user_id: number;
  project_id: number;
  employee_type: string;
}

interface RowData {
  User: {
    firstName: string;
    lastName: string;
  };
  actual_cost: string;
  billing_cost: string;
  employee_type: string;
  id: string;
}

const DeleteConfirmationModal: React.FC<{
  onDelete: () => void;
  onCancel: () => void;
}> = ({ onDelete, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
          Delete Expense Type
        </h2>
        <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
          Are you sure you want to delete this item?
        </h4>
        <div className="flex space-x-4 justify-center">
          <button
            onClick={onDelete}
            className="buttoncss text-white px-12 py-2 rounded mr-2"
          >
            Yes
          </button>
          <button
            onClick={onCancel}
            className="borderButton text-black rounded px-12 py-2"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

const AddResources = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<RowData | null>(null);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const [projectResources, setProjectResources] = useState<ProjectResource[]>(
    []
  );

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [type, setType] = useState<string[]>([]);

  const handleClose = () => setOpen(false);

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    }, 100),
    []
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const param = useParams();
  const location = useLocation();

  const handleDeleteClick = (item: RowData) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/project-resources/${selectedItem.id}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
        if (response.ok) {
          setTableData(
            tableData?.filter((item) => item.id !== selectedItem.id)
          );
        } else {
          console.error("Failed to delete the item");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setShowModal(false);
        setSelectedItem(null);
      }
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleEmployeeSelect = (employeeIds: string[]) => {};

  const handleEmployeeSelectId = (userIds: string[]) => {
    if (!param.id) {
      console.error("param.id is undefined");
      return;
    }

    const newTypeArray =
      value === 0
        ? [...type, ...userIds.map(() => "Manager")]
        : [...type, ...userIds.map(() => "Employee")];

    setType(newTypeArray);

    const projectId = parseInt(param.id);
    if (isNaN(projectId)) {
      console.error("param.id is not a valid number");
      return;
    }

    const newResources = userIds.map((id, index) => ({
      user_id: parseInt(id),
      project_id: projectId,
      employee_type: newTypeArray[index],
    }));
    setProjectResources([...newResources]);
  };

  const handleEmployeeSelect2 = (employeeIds: string) => {};

  const handleEmployeeSelectId2 = (userId: string) => {
    if (!param.id) {
      console.error("param.id is undefined");
      return;
    }

    const projectId = parseInt(param.id);
    if (isNaN(projectId)) {
      console.error("param.id is not a valid number");
      return;
    }

    const newResources = [
      {
        user_id: parseInt(userId),
        project_id: projectId,
        employee_type: "Manager",
      },
    ];
    setProjectResources([...newResources]);
  };

  console.log("1211", projectResources);

  const handleSave = () => {
    postData();
  };

  const postData = async () => {
    // setLoading(true);
    try {
      const response = await createResourcesApi(
        // customer_id,
        // uploadedFiles.map((files) => files?.originalName.split(".")),
        projectResources
      );
      const projectId = response.data.result[0].project_id;

      setOpen(true);

      setTimeout(() => {
        setOpen(false);
        navigate(`/add-task/${projectId}`, {
          state: { id: projectId },
        });
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    //  setLoading(false);
  };

  const fetchData = async () => {
    //setLoading(true);
    try {
      const response = await Fetch_Resources_List(
        param.id,
        searchTerm,
        numOfItems,
        page
        // startDate,
        // endDate
      );
      if (response.status === 200) {
        setTableData(response.data.result.rows);
        setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, numOfItems, searchTerm]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");

  const handleEditClick = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
    setIsModalOpen(true);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <>
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              height: "80vh",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: "10px 20px",
              borderRadius: "8px",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography id="modal-title" variant="h6" component="h2">
                Resource
              </Typography>
              <IconButton onClick={handleClose}>X</IconButton>
            </Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="resource tabs"
            >
              <Tab label="Manager" />
              <Tab label="Employee" />
            </Tabs>

            <Box
              display="flex"
              justifyContent="space-between"
              mt={2}
              gap="16px"
              alignItems="start"
            >
              <ManagerSearch
                onEmployeeSelect={handleEmployeeSelect2}
                onEmployeeSelectId={handleEmployeeSelectId2}
              />
              <ResourceSearch
                onEmployeeSelect={handleEmployeeSelect}
                onEmployeeSelectId={handleEmployeeSelectId}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  background: "linear-gradient(to right, #6a11cb, #2575fc)",
                  height: "50px",
                  marginTop: "3px",
                  marginBottom: "3px",
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        </>
      </Modal>

      <div className="">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/add-project")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Add Resources
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate("/add-project")}
              className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center rounded-lg self-stretch borderButton h-12"
              //onClick={(event) => handleCurrentPageChange("table", event)}
            >
              <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <button
              type="submit"
              form="form"
              className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
              onClick={() => navigate(`/add-task/${param.id}`)}
            >
              <div
                // onClick={handleOpen}
                className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
              >
                Continue
              </div>
            </button>
          </div>
        </div>

        <div className="flex justify-between mt-10">
          <Search
            onChange={updateSearchTerm}
            label="Search with resource name"
          />
          <button
            onClick={() => setOpen(true)}
            className="flex items-center font-[600] text-[14px] py-2 px-4 buttoncss text-white rounded-lg"
          >
            <img className="w-4 h-4 mr-2" src={upload} alt="plus icon" />
            Add Resources
          </button>
        </div>

        <div className="overflow-x-auto mt-10">
          <table className="w-full min-w-max text-left table-auto border-b-[0.6px] bg-white border-[#E0E3E7]">
            <thead className="bg-[#E1E1E1]">
              <tr className="h-11 flex justify-between">
                <th className="pl-8 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                  Resources
                </th>
                <th className="pl-14 text-center pr-0.5 py-2.5 text-[14px] text-[#1D1A22]">
                  Billable Cost/hr (Dollar)
                </th>
                <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                  Actual Cost/hr (Dollar)
                </th>
                <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                  Variance
                </th>
                <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((items) => (
                <tr className="h-10 border-[0.6px] flex justify-between border-solid border-[#E0E3E7] hover:bg-gray-100">
                  <td className="relative text-[#3F4254] font-[500] text-[12px] pl-8 pr-2.5 py-1">
                    {items?.employee_type === "Manager" && (
                      <div className="flex h-full items-center absolute left-0 top-0">
                        <div className="h-8 rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none w-[22px] bg-[#EAE0FF] flex items-center justify-center">
                          <span className="font-[Poppins] font-semibold text-[12px] leading-[18px] text-[#1D1A22]">
                            M
                          </span>
                        </div>
                      </div>
                    )}
                    {items?.User?.firstName} {items?.User?.lastName}
                  </td>
                  <td className="text-[#3F4254] text-center font-[500] text-[12px] py-1">
                    {items?.actual_cost || "--"}
                  </td>
                  <td className="text-[#3F4254] font-[500] text-center text-[12px] pl-6 pr-2.5 py-1">
                    {items?.billing_cost || "--"}
                  </td>
                  <td className="text-[#3F4254] font-[500] text-center text-[12px] pl-6 pr-2.5 py-1">
                    {Number(items?.actual_cost) - Number(items?.billing_cost)}
                  </td>
                  <td className="flex items-center pl-6 pr-2.5 py-1">
                    <div className="flex items-center justify-end gap-2">
                      <img
                        src={edit}
                        onClick={() => handleEditClick(items.id)}
                        alt="Edit"
                        className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      />
                      <img
                        src={del}
                        onClick={() => handleDeleteClick(items)}
                        alt="Edit"
                        className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {tableData?.length === 0 ? (
            <div className="w-full h-10 px-2.5 py-1.5 flex justify-center items-center bg-white shadow-sm font-[Poppins] text-base text-[#1D1A22] border border-[#EBEBEB]">
              Data not Found!
            </div>
          ) : (
            <></>
          )}
          {count >= 10 && (
            <TablePaginationDemo
              count={count}
              handleItemsChange={handleNumberOfPages}
              handlePageChange={handlePageChange}
              currentPage={1}
              numOfItems={10}
            />
          )}

          {showModal && (
            <DeleteConfirmationModal
              onDelete={handleConfirmDelete}
              onCancel={handleCancelDelete}
            />
          )}

          <ModalResources
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            employeeId={selectedEmployeeId}
          />
        </div>
      </div>
    </>
  );
};

export default AddResources;
