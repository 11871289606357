import { TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fetch_Employee_List_List_Management } from "../../redux/actions/Employee/Employee";

interface Employee {
  id: string;
  firstName: string;
  lastName: string;
  employee_id: string;
  avatarUrl: string;
}

interface EmployeeSearchProps {
  onEmployeeSelect: (employeeId: string) => void;
  onEmployeeSelectId: (user_Id: string) => void;
}

const EmployeeSearch: React.FC<EmployeeSearchProps> = ({
  onEmployeeSelect,
  onEmployeeSelectId,
}) => {
  const employee = useSelector((state: any) => state.emplist?.EmployeeData);
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const [searchQuery, setSearchQuery] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  useEffect(() => {
    dispatch<any>(
      Fetch_Employee_List_List_Management(customer_id, 100, 1, searchQuery)
    );
  }, [searchQuery, dispatch, customer_id]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredEmployees = employee.filter(
    (emp: { employee_id: string; firstName: string }) =>
      emp.employee_id?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      emp.firstName?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setIsDropdownOpen(true);
  };

  const handleOptionClick = (employee: Employee) => {
    setSearchQuery(employee.firstName);
    setIsDropdownOpen(false);
    onEmployeeSelect(employee.employee_id);
    onEmployeeSelectId(employee.id);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <TextField
        required
        id="outlined-required"
        label="Employee Name & Id"
        name="empId"
        placeholder="Search with Employee name & ID"
        onChange={handleInputChange}
        className="w-[95%]"
        value={searchQuery}
      />
      {isDropdownOpen && (
        <div className="absolute mt-2 w-full p-4 bg-white shadow-lg rounded-lg max-h-80 overflow-y-auto z-10">
          {filteredEmployees.length > 0 ? (
            filteredEmployees.map((employee: Employee) => (
              <div
                key={employee.id}
                className="flex items-center p-2 mb-2 bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-lg"
                onClick={() => handleOptionClick(employee)}
              >
                <div>
                  <p className="font-semibold">
                    {employee.firstName} {employee.lastName}
                  </p>
                  <p className="text-gray-600">{employee.employee_id}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-600">No results found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default EmployeeSearch;
