import React, { useCallback, ChangeEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { debounce } from "lodash";

import filter from "../../assets/filter.svg";
import download from "../../assets/download_new.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import view from "../../assets/Crud_Icons/view.svg";
import profile from "../../assets/Ellipse 580.png";

import Search from "../SearchBar/Search";
import TablePaginationDemo from "../common/Pagenation";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Fetch_Asset_List } from "../common/services/Asset";
import moment from "moment";
import TableSkeleton from "../common/TableSkeleton";

interface AssignAssetData {
  id: number;
  asset_name: string;
  assigned_date: string;
  acknowledgment_status: boolean;
  User: {
    firstName: string;
    lastName: string;
    employee_id: string;
    profile_image: string;
  };
  AssetCategory: {
    id: number;
    category: string;
  };
}

function AssignedAsset() {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [rowData, setRowData] = useState<AssignAssetData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    }, 100),
    []
  );

  const handleNumberOfPages = (value: number) => {
    setNumOfItems(value);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Asset_List(
        customer_id,
        numOfItems,
        page,
        searchTerm
      );
      if (response.status === 200) {
        setRowData(response.data.result.rows);
        setCount(response.data.result.count);
      } else {
        toast.error("Failed to fetch data");
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [customer_id, page, numOfItems, searchTerm]);

  return (
    <div className="pt-12">
      <div className="flex flex-col gap-5">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with employee Name"
            />
            <div className="h-full flex items-center gap-5">
              {/* <div className="h-full flex items-center gap-2">
                
                {departmentSelect && (
                  <Chip
                    label={selectedDepartmentUnit?.name}
                    onDelete={handleDeleteDepartment}
                    size="small"
                  />
                )}
                {businessSelect && (
                  <Chip
                    label={selectedBusinessUnit?.name}
                    onDelete={handleDeleteBusiness}
                    size="small"
                  />
                )}
              </div> */}
              <button
                className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
                // onClick={() => setIsOpen(true)}
              >
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                  Filter
                </span>
              </button>
              <div className="h-full">
                <button className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text">
                  <img
                    src={download}
                    alt="download"
                    className="h-[15px] w-[15px]"
                  />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                    Download Report
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-end pr-1 gap-[31px]">
          <div className="flex flex-row justify-between gap-1 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#00CBA0" />
            </svg>
            <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
              Acknowledgment Submitted
            </span>
          </div>

          <div className="flex flex-row justify-between gap-1 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="3.99537" cy="3.74537" r="3.49537" fill="#FF9950" />
            </svg>
            <span className="text-[#262626] font-[Poppins] text-[10px] font-medium leading-[13.981px]">
              Acknowledgement Pending
            </span>
          </div>
        </div>
        <div className="overflow-x-auto overflow-y-auto shadow-sm">
          {loading ? (
            <>
              <table className="w-full min-w-max text-left table-auto bg-white">
                <thead className="rounded-t-[3px] border border-[#E0E0E0]">
                  <tr className="h-11 w-full">
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-5">
                      Category
                    </th>
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4">
                      Asset Name
                    </th>
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-3">
                      Assigned to
                    </th>
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4">
                      Assigned Date
                    </th>
                    <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pr-5 flex justify-end">
                      Action
                    </th>
                  </tr>
                </thead>
              </table>
              <TableSkeleton />
            </>
          ) : (
            <table className="w-full min-w-max text-left table-auto bg-white">
              <thead className="rounded-t-[3px] border border-[#E0E0E0]">
                <tr className="h-11 w-full">
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-5">
                    Category
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4">
                    Asset Name
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pl-3">
                    Assigned to
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4">
                    Assigned Date
                  </th>
                  <th className="font-[Poppins] text-[14px] leading-[14px] font-medium text-[#1D1A22] py-4 pr-5 flex justify-end">
                    Action
                  </th>
                </tr>
              </thead>
              {rowData?.map((item) => (
                <tbody className="border border-[#EBEBEB]">
                  <tr className="h-12 border-t-0 border-l-0 border-r-0 border-b border-[#EBEBEB] hover:bg-gray-100">
                    <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F] pl-3">
                      <div className="flex items-center gap-2 h-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 8 8"
                          fill="none"
                        >
                          <circle
                            cx="3.99537"
                            cy="3.74537"
                            r="3.49537"
                            fill={
                              item?.acknowledgment_status ? "#00CBA0" : "#FF9950"
                            }
                          />
                        </svg>
                        <span>{item?.AssetCategory?.category || "--"}</span>
                      </div>
                    </td>
                    <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F]">
                      {item?.asset_name || "--"}
                    </td>
                    <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F]">
                      <div className="h-[40px] flex items-center gap-2.5">
                        <div className="h-full w-[40px] rounded-full">
                          <img
                            src={item?.User?.profile_image || profile}
                            alt="profile"
                            className="h-full w-full rounded-full"
                          />
                        </div>
                        <div className="h-full flex flex-col justify-center">
                          <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                            {item?.User?.firstName} {item?.User?.lastName}
                          </span>
                          <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                            {item?.User?.employee_id}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="font-[Poppins] font-medium text-[12px] leading-[13.98px] text-[#49454F]">
                      {moment(item?.assigned_date).format("DD MMM, YYYY")}
                    </td>
                    <td className="pr-5">
                      <div className="flex justify-end items-center gap-2">
                        <img
                          className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                          onClick={() =>
                            navigate(`/view-asset`, {
                              state: { id: item.id },
                            })
                          }
                          src={view}
                          alt="View"
                        />
                        <img
                          className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                          onClick={() =>
                            navigate(`/edit-asset`, {
                              state: { id: item.id },
                            })
                          }
                          src={edit}
                          alt="Edit"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          )}

          {!loading && rowData?.length === 0 ? (
            <div className="w-full h-10 px-2.5 py-1.5 flex justify-center items-center bg-white shadow-sm font-[Poppins] text-base text-[#1D1A22] border border-[#EBEBEB]">
              Data not Found!
            </div>
          ) : (
            <></>
          )}
        </div>
        {!loading && count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={1}
            numOfItems={10}
          />
        )}
      </div>
    </div>
  );
}

export default AssignedAsset;
