import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import right from "../../image/chevron-right.png";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import check from "../../image/checkmark-circle-02.svg";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import { CountryCode } from "libphonenumber-js/core";
import { MuiTelInput } from "mui-tel-input";
import axios from "axios";
import {
  Department_Unit,
  Designation_Manager,
  EmployeeUnit,
  Reporting_Manager,
  Role_Unit,
  Team_Manager,
} from "../../redux/actions/Employee/Employee";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchEditEmployee, updateEmployee } from "../common/services/Employee";
import toast from "react-hot-toast";
import { inputStyle } from "../../utils/helperData";
import moment from "moment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface DepartmentUnit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface Team_Unit {
  firstName: string;
  lastName: string;
  id: Number;
}
interface Report_Unit {
  firstName: string;
  lastName: string;
  id: Number;
}

interface Designation_Unit {
  name: string;
  id: Number;
}

interface Role_Data {
  role_name: string;
  id: Number;
}

interface FormValues {
  employeeCode: string;
  employeeId: string;
  prefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  experience: Number;
}

export const EditEmployee = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customer_id = 0;
  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }
  const location = useLocation();
  const id = location.state?.id;

  const [birth, setBirth] = React.useState<Dayjs | string | null>(null);
  const [joining, setJoining] = React.useState<Dayjs | string | null>(null);
  const [leaving, setLeaving] = React.useState<Dayjs | null>(null);
  const [department, setDepartment] = useState<DepartmentUnit[]>();
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [team, setTeam] = useState<Team_Unit[]>();
  const [selectReport, setSelectReport] = useState<Report_Unit[]>();
  const [designation, setDesignation] = useState<Designation_Unit[]>();
  const [roledata, setRoleData] = useState<Role_Data[]>();
  const [selectedBusiness, setSelectedBusiness] = useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [empStatus, setEmpStatus] = useState<string>("");
  const [modeOfEmp, setModeOfEmp] = useState<string>("");
  const [selectTeam, setSelectTeam] = useState<string>("");
  const [report, setReport] = useState<string>("");
  const [selectDesignation, setSelectDesignation] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [countryCode, setCountryCode] = React.useState("");
  const [countryInitial, setCountryInitial] = useState<CountryCode>();
  const [nationalNumber, setNationalNumber] = useState("");
  const [errors, setErrors] = useState<Partial<FormValues>>({});
  const [formValues, setFormValues] = useState<FormValues>({
    employeeCode: "",
    employeeId: "",
    prefix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    experience: 0,
  });

  const EmployeeStatus = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "archived", label: "Archived" },
  ];

  const Prefix = [
    { value: "Mr.", label: "Mr." },
    { value: "Ms.", label: "Ms." },
    { value: "Mrs.", label: "Mrs." },
  ];

  const ModeOfEmployment = [
    { value: "full_time", label: "Full Time" },
    { value: "part_time", label: "Part Time" },
    { value: "contract", label: "Contract" },
    { value: "internship", label: "Intern" },
  ];

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    if (formValues.firstName === "")
      tempErrors.firstName = "First Name is required";
    if (formValues.email === "") tempErrors.email = "Email is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/employee-management");
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCancel = () => {
    navigate("/employee-management");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDepartment(event.target.value);
  };

  const handleBusiness = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedBusiness(Number(event.target.value));
  };

  const handleEmpStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmpStatus(event.target.value);
  };

  const handleModeOfEmp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModeOfEmp(event.target.value);
  };

  const handleTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectTeam(event.target.value);
  };

  const handleRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value);
  };

  const handleReport = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReport(event.target.value);
  };

  const handleDesignation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectDesignation(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handlePhoneChange = (value: string, info: any) => {
    setPhone(value);
    setNationalNumber(info?.nationalNumber);
    setCountryCode(`+${info?.countryCallingCode}`);
  };

  const handleClick = async () => {
    validate();
    submitChanges();
  };

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchDepartment();
  }, []);

  useEffect(() => {
    const fetchRoledata = async () => {
      try {
        const historyData = await dispatch<any>(
          Role_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setRoleData(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchRoledata();
  }, []);

  const fetchDesignation = async () => {
    try {
      const firstResponse = await dispatch<any>(
        Designation_Manager(customer_id, navigate, 1)
      );

      const totalCount = firstResponse?.result.count || 0;
      const totalPages = Math.ceil(totalCount / 10);

      const remainingPages = [];
      for (let num = 2; num <= totalPages; num++) {
        remainingPages.push(num);
      }

      const remainingResponses = await Promise.all(
        remainingPages.map(async (num) => {
          return await dispatch<any>(
            Designation_Manager(customer_id, navigate, num)
          );
        })
      );

      const allDesignations = [
        ...firstResponse.result.rows,
        ...remainingResponses.flatMap(
          (response) => response?.result.rows || []
        ),
      ];

      setDesignation(allDesignations);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  useEffect(() => {
    fetchDesignation();
  }, []);

  useEffect(() => {
    const fetchTeamManager = async () => {
      try {
        const historyData = await dispatch<any>(
          Team_Manager(Number(selectedBusiness), navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setTeam(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchTeamManager();
  }, [selectedBusiness]);

  useEffect(() => {
    const fetchReporting = async () => {
      try {
        const historyData = await dispatch<any>(
          Reporting_Manager(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.userList.rows;
          setSelectReport(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchReporting();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchEditEmployee(id);
        if (data.result) {
          const phonevalue = data.result.country_code + " " + data.result.phone;

          setFormValues({
            employeeCode: data?.result?.employee_code || "",
            employeeId: data?.result?.employee_id || "",
            prefix: data?.result?.prefix || "",
            firstName: data?.result?.firstName || "",
            middleName: data.result?.middleName || "",
            lastName: data?.result?.lastName || "",
            email: data?.result?.email || "",
            experience: Number(data?.result?.experience),
          });
          setSelectedDepartment(data?.result?.Group?.id);
          setSelectedBusiness(data?.result?.Department?.id);
          setModeOfEmp(data?.result?.mode_of_employment);
          setPhone(phonevalue);
          setCountryCode(data?.result?.country_code);
          setEmpStatus(data?.result?.status);
          setSelectTeam(data?.result?.team_manager?.id);
          setSelectDesignation(data?.result?.Designation?.id);
          setReport(data?.result?.reporting_manager?.id);
          // setBirth(dayjs(data?.result?.date_of_birth));
          setJoining(dayjs(data?.result?.date_of_joining));
          setRole(data?.result?.Role?.id);
          {
            data?.result?.date_of_birth
              ? setBirth(dayjs(data?.result?.date_of_birth))
              : setBirth(null);
          }
          {
            data?.result?.date_of_leaving
              ? setLeaving(dayjs(data?.result?.date_of_leaving))
              : setLeaving(null);
          }
          // setLeaving(dayjs(data?.result?.date_of_leaving));
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    };

    fetchData();
  }, []);

  const submitChanges = async () => {
    try {
      const data = await updateEmployee(
        id,
        formValues.employeeCode,
        formValues.employeeId,
        formValues.email,
        nationalNumber,
        formValues.prefix,
        formValues.firstName,
        formValues.middleName,
        formValues.lastName,
        countryCode,
        Number(selectedDepartment),
        customer_id,
        Number(role),
        Number(selectedBusiness),
        Number(selectDesignation),
        Number(selectTeam),
        Number(report),
        empStatus,
        modeOfEmp,
        formValues.experience,
        moment(birth?.toString()).format("YYYY-MM-DD"),
        moment(joining?.toString()).format("YYYY-MM-DD"),
        moment(leaving?.toString()).format("YYYY-MM-DD")
      );

      if (data && data.result) {
        setOpen(true);

        setTimeout(() => {
          setOpen(false);
          navigate("/exit-procedure");
        }, 1000);
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
  };

  const ConditionalComponent: React.FC = () => {
    const location = useLocation();

    if (location.pathname === "/add-employee") {
      return <div>Employee details added successfully!</div>;
    } else if (location.pathname === "/edit-employee") {
      return <div>Employee details updated successfully!</div>;
    } else {
      return <div>Default Content</div>;
    }
  };

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <img style={{ margin: "auto" }} src={check} alt="" />
            </Typography>
            <Typography
              style={{ textAlign: "center" }}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              <ConditionalComponent />
            </Typography>
          </Box>
        </Modal>
      </div>

      <div>
        <div className="bg-[#FBFBFB] px-4 pt-6 pb-12 h-full rounded-md">
          <div className="flex flex-row items-center h-12 justify-between">
            <div className="flex flex-row gap-[13px] justify-center items-center">
              <button onClick={() => navigate("/employee-management")}>
                <img
                  src={right}
                  alt="right arrow sign"
                  className="h-6 w-6 shrink-0 bg-black rounded-full"
                />
              </button>
              <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                Edit Employee Details
              </span>
            </div>
            <div className="flex flex-start gap-5">
              <button
                onClick={handleCancel}
                className="flex w-[148px] borderButton p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch h-12"
              >
                <div className="text text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Cancel
                </div>
              </button>
              <button
                type="submit"
                form="form"
                className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
                onClick={handleClick}
              >
                <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Update
                </div>
              </button>
            </div>
          </div>
          <div className="rounded overflow-hidden shadow-custom bg-white mt-10">
            <div className="container mx-auto p-4">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1 },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className="grid grid-cols-3 gap-4">
                  <TextField
                    required
                    id="outlined"
                    label="Employee Code"
                    name="employeeCode"
                    value={formValues.employeeCode}
                    onChange={handleFrormChange}
                  />
                  <TextField
                    required
                    id="outlined"
                    label="Employee Id"
                    name="employeeId"
                    value={formValues.employeeId}
                    onChange={handleFrormChange}
                  />
                  <TextField
                    select
                    id="outlined-required"
                    label="Prifix"
                    defaultValue=" "
                    name="prefix"
                    value={formValues.prefix}
                    onChange={handleFrormChange}
                  >
                    {Prefix.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    label="First Name"
                    defaultValue=" "
                    name="firstName"
                    value={formValues.firstName}
                    onChange={handleFrormChange}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                  />
                  <TextField
                    id="outlined-required"
                    label="Middle Name"
                    defaultValue=" "
                    name="middleName"
                    value={formValues.middleName}
                    onChange={handleFrormChange}
                  />
                  <TextField
                    id="outlined-required"
                    label="Last Name"
                    defaultValue=" "
                    name="lastName"
                    value={formValues.lastName}
                    onChange={handleFrormChange}
                  />
                  <TextField
                    required
                    id="outlined-required"
                    label="Email"
                    defaultValue=" "
                    name="email"
                    value={formValues.email}
                    onChange={handleFrormChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />

                  <div className="flex flex-row mt-1">
                    <MuiTelInput
                      required
                      label="Phone Number"
                      id="phone"
                      name="phone"
                      placeholder="Phone Number"
                      defaultCountry={countryInitial}
                      focusOnSelectCountry
                      autoComplete="off"
                      className="w-full h-[2.5rem] border-opacity-30 rounded-[3px] border border-[#262626] px-2"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        sx: {
                          ...inputStyle,
                          alignItems: "center",
                          flexDirection: "row",
                          marginBottom: "8px",
                          borderRadius: "3px",
                          height: "4.5rem",
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        },
                      }}
                      value={phone}
                      onChange={handlePhoneChange}
                    />
                  </div>

                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Business Unit"
                    value={selectedDepartment}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    {bussness?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Department"
                    value={selectedBusiness}
                    onChange={handleBusiness}
                  >
                    {department?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Designation"
                    defaultValue="hello"
                    value={selectDesignation}
                    onChange={handleDesignation}
                  >
                    {designation?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Role"
                    defaultValue="hello"
                    value={role}
                    onChange={handleRole}
                  >
                    {roledata?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.role_name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="outlined-required"
                    select
                    label="Team Manager"
                    defaultValue="hello"
                    value={selectTeam}
                    onChange={handleTeam}
                  >
                    {team?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.firstName} {option.lastName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Reporting Manager"
                    defaultValue="hello"
                    value={report}
                    onChange={handleReport}
                  >
                    {selectReport?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.firstName} {option.lastName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Employee Status"
                    defaultValue="hello"
                    value={empStatus}
                    onChange={handleEmpStatus}
                  >
                    {EmployeeStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Mode Of Employment"
                    defaultValue="hello"
                    value={modeOfEmp}
                    onChange={handleModeOfEmp}
                  >
                    {ModeOfEmployment.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    label="Years Of Experience"
                    defaultValue=" "
                    name="experience"
                    value={formValues.experience}
                    onChange={handleFrormChange}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date Of Birth"
                      value={birth}
                      onChange={(newValue) => {
                        setBirth(newValue);
                      }}
                      maxDate={dayjs()}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date Of Joining"
                      value={joining}
                      onChange={(newValue) => {
                        setJoining(newValue);
                      }}
                      maxDate={dayjs()}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date Of Leaving"
                      value={leaving}
                      onChange={(newValue) => {
                        setLeaving(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
